<section>
    <div class="rev_slider_wrapper">
        <div class="rev_slider" id="slider4" data-version="5.0">
            <!-- <ul> -->
                <!-- SLIDE Principal -  GESTIÓN ENERGÉTICA -->
               
                    <!-- <img id="gestionenergetica" src="img/revolution/gestionenergetica.webp" alt="<%= __('home.slider.item1.title')%>"
                        data-bgrepeat="no-repeat" data-bgfit="cover" data-bgparallax="7" class="rev-slidebg">
                     -->

                     <picture>
                        <source srcset="assets/img/imagen-cabecera.webp" type="image/webp" alt="Imagen Cabecera" >
                        <source srcset="assets/img/imagen-cabecera.jpg" type="image/jpeg" alt="Imagen Cabecera"> 
                        <img fetchPriority="high" id="imagenCabecera" src="assets/img/imagen-cabecera.jpg" alt="Imagen Cabecera"/>
                      </picture>

                        <!--<img id="imagenCabecera" src="assets/img/imagen-cabecera.jpg" alt="Imagen Cabecera"
                        data-bgrepeat="no-repeat" data-bgfit="cover" data-bgparallax="7" class="rev-slidebg">-->

                 <!-- end slide Gestión Energética -->
                  <!-- SLIDE Mantenimiento -->
                  <!-- <li data-transition="scaledownfrombottom" data-easein="default" data-easeout="default"
                  data-slotamount="1" data-masterspeed="1200" data-delay="8000" data-title="<%= __('home.slider.item2.title')%>"> -->
                  <!-- MAIN IMAGE -->
                  <!-- <img id="gestionenergetica" src="img/revolution/mantenimiento.webp" alt="<%= __('home.slider.item2.title')%>"
                      data-bgrepeat="no-repeat" data-bgfit="cover" data-bgparallax="7" class="rev-slidebg">
                         -->
                  <!-- LAYER NR. 1 -->
                  <!-- <div class="tp-caption medium-text size-24 nocaps alt-font-2" data-x="center" data-y="center"
                      data-voffset="-85" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]"
                      data-transform_idle="o:1;s:1000" data-transform_in="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;"
                      data-transform_out="opacity:0;s:1000;e:Power3.easeInOut;" data-start="1000" style="text-align: center;"><%= __('home.slider.item2.subtitle')%>
                  </div> -->

                  <!-- LAYER NR. 1 -->
                  <!-- <div class="tp-caption hero-text size-95" data-x="center" data-y="center" data-voffset="0"
                      data-fontsize="[95,85,75,45]" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]"
                      data-transform_idle="o:1;s:900" data-transform_in="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;"
                      data-transform_out="opacity:0;s:1000;e:Power3.easeInOut;" data-start="1200" style="text-align: center;"><%= __('home.slider.item2.title')%>
                  </div> 
              </li> -->
              <!-- end slide Mantenimiento -->

              <!-- SLIDE Eficiencia Energética -->
              <!-- <li data-transition="scaledownfrombottom" data-easein="default" data-easeout="default"
                  data-slotamount="1" data-masterspeed="1200" data-delay="8000" data-title="<%= __('home.slider.item3.title')%>"> -->
                  <!-- MAIN IMAGE -->
                  <!-- <img id="gestionenergetica" src="img/revolution/eficienciaenergetica.webp" alt="<%= __('home.slider.item3.title')%>"
                      data-bgrepeat="no-repeat" data-bgfit="cover" data-bgparallax="7" class="rev-slidebg">
                 -->
                  <!-- LAYER NR. 1 -->
                  <!-- <div class="tp-caption medium-text size-24 nocaps alt-font-2" data-x="center" data-y="center"
                      data-voffset="-85" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]"
                      data-transform_idle="o:1;s:1000" data-transform_in="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;"
                      data-transform_out="opacity:0;s:1000;e:Power3.easeInOut;" data-start="1000" style="text-align: center;"><%= __('home.slider.item3.subtitle')%>
                  </div> -->

                  <!-- LAYER NR. 1 -->
                  <!-- <div class="tp-caption hero-text size-95" data-x="center" data-y="center" data-voffset="0"
                      data-fontsize="[95,85,75,45]" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]"
                      data-transform_idle="o:1;s:900" data-transform_in="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;"
                      data-transform_out="opacity:0;s:1000;e:Power3.easeInOut;" data-start="1200" style="text-align: center;"><%= __('home.slider.item3.title')%>
                  </div> -->
              <!-- </li> -->
              <!-- end slide Eficiencia Energetica -->

              <!-- SLIDE Ingenieria y Proyectos -->
              <!-- <li data-transition="scaledownfrombottom" data-easein="default" data-easeout="default"
                  data-slotamount="1" data-masterspeed="1200" data-delay="8000" data-title="<%= __('home.slider.item4.title')%>"> -->
                  <!-- MAIN IMAGE -->
                  <!-- <img id="gestionenergetica" src="img/revolution/ingenieriayproyectos.webp" alt="<%= __('home.slider.item4.title')%>"
                      data-bgrepeat="no-repeat" data-bgfit="cover" data-bgparallax="7" class="rev-slidebg">
                    -->
                  <!-- LAYER NR. 1 -->
                  <!-- <div class="tp-caption medium-text size-24 nocaps alt-font-2" data-x="center" data-y="center"
                      data-voffset="-85" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]"
                      data-transform_idle="o:1;s:1000" data-transform_in="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;"
                      data-transform_out="opacity:0;s:1000;e:Power3.easeInOut;" data-start="1000" style="text-align: center;"><%= __('home.slider.item4.subtitle')%>
                  </div> -->

                  <!-- LAYER NR. 1 -->
                  <!-- <div class="tp-caption hero-text size-95" data-x="center" data-y="center" data-voffset="0"
                      data-fontsize="[95,85,75,45]" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]"
                      data-transform_idle="o:1;s:900" data-transform_in="x:0;y:150;z:0;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;"
                      data-transform_out="opacity:0;s:1000;e:Power3.easeInOut;" data-start="1200" style="text-align: center;"><%= __('home.slider.item4.title')%>
                  </div>
              </li> -->
              <!-- Ingenieria y Proyectos -->                      
            <!-- </ul> -->
        </div>
    </div>
</section>