import { Component } from '@angular/core';

@Component({
  selector: 'app-revolution-slider',
  standalone: true,
  imports: [],
  templateUrl: './revolution-slider.component.html',
  styleUrl: './revolution-slider.component.css'
})
export class RevolutionSliderComponent {

}
